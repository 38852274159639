import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import { NgbCarousel, NgbSlideEvent } from "@ng-bootstrap/ng-bootstrap";
import { FullscreenService } from "src/app/components/fullscreen-image/fullscreen.service";

@Component({
    selector: 'app-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnChanges, OnInit {
    @Input() src!: Array<any>;
    @Input() customString?: string;

    images!: Array<any>;
    imageZoom: number = 1;
    imageLoaded: boolean = false;
    positionChanged: boolean = false;
    dragPosition = { x: 0, y: 0 };
    currentSlide: number = 0;
    rotation: number = 0;
    emptySrc: boolean = false;
    // pdf
    zoom: number = 1;
    showZoom: boolean = false;

    @Output() indexChange = new EventEmitter<number>();
    @Output() emptySrcChange = new EventEmitter<boolean>();

    @ViewChildren("currentImage") currentImage!: QueryList<ElementRef>;
    @ViewChild("carousel", { static: true }) carousel!: NgbCarousel;

    constructor(private fullScreenService: FullscreenService) { }

    initImages() {
        this.emptySrc = false;
        this.images = this.src;
        // Filtrar documentos vazios
        this.images = this.images.filter((image: any) => ([null, undefined, '']).includes(image) === false);
        this.images = this.images.filter((image: any) => ([null, undefined, '']).includes(image.ext) === false);
        this.images = this.images.filter((image: any) => ([null, undefined, '']).includes(image.urlView) === false);
        if (!this.images) {
            this.emptySrcChange.emit(true);
            this.emptySrc = true;
            return;
        }
        this.carousel.pause();
    }

    ngOnInit(): void {
        this.initImages();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.imageLoaded = false;
        this.initImages();
    }

    zoomIn() {
        this.positionChanged = true;
        this.imageZoom += 0.25;
        this.zoom = this.zoom + 0.1;
        if (this.images[this.currentSlide].ext !== 'pdf') {
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.scale = this.imageZoom;
        }
        this.changedZoom();
    }
    zoomOut() {
        this.positionChanged = true;
        if (this.imageZoom > 0.25) this.imageZoom -= 0.25;
        this.zoom = this.zoom - 0.1;
        if (this.images[this.currentSlide].ext !== 'pdf') {
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.scale = this.imageZoom;
        }
        this.changedZoom();
    }

    OnSlide(event: NgbSlideEvent) {
        this.resetImagePosition();
        if (event.direction === "start") this.currentSlide++;
        else this.currentSlide--;
        if (this.currentSlide > this.currentImage.length - 1) this.currentSlide = 0;
        else if (this.currentSlide < 0)
            this.currentSlide = this.currentImage.length - 1;
        this.indexChange.emit(this.currentSlide);
    }

    resetImagePosition() {
        this.dragPosition = { x: 0, y: 0 };
        this.positionChanged = false;
        this.rotation = 0;
        if (this.images[this.currentSlide].ext !== 'pdf') {
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.transform = `rotate(${this.rotation}deg)`;
            this.imageZoom = 1;
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.scale = this.imageZoom;
        }
        this.zoom = 1;
    }

    openFullScreenMode() {
        if (this.images[this.currentSlide].ext === 'pdf') window.open(this.images[this.currentSlide].url, "_blank");
        this.fullScreenService.openFullScreenMode(
            this.currentImage.map((x) => x)[this.currentSlide].nativeElement.src,
            this.customString ?? this.customString
        );
    }

    rotateLeft() {
        this.positionChanged = true;
        this.rotation -= 90;
        if (this.images[this.currentSlide].ext !== 'pdf') {
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.transform = `rotate(${this.rotation}deg)`;
        }
    }

    rotateRight() {
        this.positionChanged = true;
        this.rotation += 90;
        if (this.images[this.currentSlide].ext !== 'pdf') {
            this.currentImage.map((x) => x)[
                this.currentSlide
            ].nativeElement.style.transform = `rotate(${this.rotation}deg)`;
        }
    }

    OnDragStart() {
        this.positionChanged = true;
    }

    next() {
        this.carousel.next();
    }

    previous() {
        this.carousel.prev();
    }

    onScroll(event: any) {
        if (this.images[this.currentSlide].ext === 'pdf') return;
        event.preventDefault();
        if (event.wheelDelta > 0) {
            this.zoomIn();
        } else {
            this.zoomOut();
        }
    }

    async changedZoom(): Promise<void> {
        this.showZoom = true;
        await new Promise(resolve => setTimeout(resolve, 1500));
        this.showZoom = false;
    }
}
